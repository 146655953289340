import React from "react";
import NavBar from "../components/navbar";
import "./index.css";
import { Helmet } from "react-helmet";
import BadImg from "../images/bad.png";

const NotFoundPage = () => {
  return (
    <div>
      <Helmet>
        <title>Diversified Conglomerates in Sri Lanka | Lalan Group</title>
        <meta
          name="title"
          content="Diversified Conglomerates in Sri Lanka | Lalan Group"
        />
        <meta
          name="description"
          content="Founded in the year 1940, Lalan group owns over 46 subsidiaries making it among the largest diversified conglomerates in Sri Lanka."
        />
        <meta
          name="keywords"
          content="Diversified Conglomerates in Sri Lanka, Lalan Group"
        />
      </Helmet>

      <NavBar />

      {/* <video
        autoPlay
        loop
        muted
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: -1,
        }}
      >
        <source
          src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/videos/home-hero-video.mp4"
          type="video/mp4"
        />
      </video> */}

      <main>
        <div className="page404-container">
          <center>
            <div>
              <img src={BadImg} alt="Logo" className="bad-img" />
            </div>
          </center>
          <h1 className="page404-h1">
            Uh-oh! Looks like we got lost.
            <br />
            Let's get back on track.
          </h1>
          <h2 className="page404-h2">
            Please visit our home page or sitemap to find the imformation we are
            looking for.
          </h2>
          <br /> <br />
          <div className="page404-buttons">
            <a href="/" className="page404-button">
              Home Page
            </a>
            <a href="/sitemap/" className="page404-button">
              Sitemap
            </a>
          </div>
        </div>
      </main>
    </div>
  );
};

export default NotFoundPage;
